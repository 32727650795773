import React, { useRef } from "react"
import { TypeAnimation } from "react-type-animation"

import {
  Wrapper,
  TextContainer,
  ExtraIndentedTextContainer,
  CabbageIcon,
  Weblink,
  WeblinkOffsite,
  ExpandButton,
} from "./styles"

import Cabbage from "../../assets/Cabbage.png"

const TypingLink = ({ text }) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const isMobile = windowSize.current[0] < 769
  const textSizeForMediaScreenWidth = isMobile ? "20px" : "36px"
  return (
    <TypeAnimation
      sequence={[text, 500, () => {}]}
      wrapper="div"
      cursor={false}
      repeat={0}
      style={{ fontSize: textSizeForMediaScreenWidth }}
    />
  )
}

export default function NavigationItem({
  label,
  onsite,
  text,
  extraIndent,
  url,
}) {
  if (onsite) {
    return (
      <Wrapper>
        <Weblink to={`/${label}`}>
          {extraIndent ? (
            <ExtraIndentedTextContainer>
              <TypingLink text={text} />
            </ExtraIndentedTextContainer>
          ) : (
            <TextContainer>
              <TypingLink text={text} />
            </TextContainer>
          )}
        </Weblink>
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <WeblinkOffsite href={url} target="_blank" rel="noopener noreferrer">
        <TextContainer>
          <TypingLink text={text} />
        </TextContainer>
      </WeblinkOffsite>
    </Wrapper>
  )
}
