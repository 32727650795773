import React, { useState, useRef, useEffect } from "react"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"
import { TypeAnimation } from "react-type-animation"

import NavigationItem from "../../components/NavigationItem"
import NavigationItemCode from "../../components/NavigationItemCode"
import ExpandButton from "../../components/ExpandButton"

import DownArrow from "../../assets/DownArrow.png"
import RightArrow from "../../assets/RightArrow.png"

import {
  Wrapper,
  CodeWrapper,
  LineNumber,
  FullLine,
  NavigationStack,
  MyName,
  Comment,
  Arrow,
  ArrowButton,
} from "./styles"

export default function Home() {
  const [toolsExpanded, setToolsExpanded] = useState(false)

  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const isMobile = windowSize.current[0] < 769
  const textSizeForMediaScreenWidth = isMobile ? "20px" : "36px"

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <article>
      <Helmet>
        <title>Home</title>
        <meta
          name="My personal site"
          content="A place where you can get to know me, joonkimchi"
        />
      </Helmet>
      <Wrapper>
        <CodeWrapper>
          <FullLine>
            <LineNumber>1</LineNumber>
            <MyName>
              <TypeAnimation
                sequence={["<JoonKimchi>", 6000, "Joon Kim", 4000, () => {}]}
                wrapper="div"
                cursor
                repeat={Infinity}
                style={{ fontSize: textSizeForMediaScreenWidth }}
              />
            </MyName>
          </FullLine>
          <FullLine>
            <LineNumber>2</LineNumber>
            <Comment>{"/* TODO: get to me know me! */"}</Comment>
          </FullLine>
          <FullLine>
            <LineNumber>3</LineNumber>
            <NavigationItemCode
              url="https://www.youtube.com/@JoonKimchi"
              label="videos"
              text="<YouTube videos=true />"
            />
          </FullLine>
          <FullLine>
            <LineNumber>4</LineNumber>
            <NavigationItemCode
              onsite
              label="about"
              text="<About joonkimchi />"
            />
          </FullLine>
          <FullLine>
            <LineNumber>5</LineNumber>
            <ArrowButton onClick={() => setToolsExpanded(!toolsExpanded)}>
              {toolsExpanded ? (
                <Arrow src={DownArrow} />
              ) : (
                <Arrow src={RightArrow} />
              )}
            </ArrowButton>
            <ExpandButton
              top
              toolsExpanded={toolsExpanded}
              setToolsExpanded={setToolsExpanded}
            />
          </FullLine>
          {toolsExpanded && (
            <>
              <FullLine>
                <LineNumber>6</LineNumber>
                <NavigationItemCode
                  extraIndent
                  onsite
                  label="toolbox/timeline"
                  text="<Timeline />"
                />
              </FullLine>
              <FullLine>
                <LineNumber>7</LineNumber>
                <NavigationItemCode
                  extraIndent
                  onsite
                  label="toolbox/applications"
                  text="<Applications />"
                />
              </FullLine>
              <FullLine>
                <LineNumber>8</LineNumber>
                <NavigationItemCode
                  extraIndent
                  onsite
                  label="toolbox/resume"
                  text="<Resume />"
                />
              </FullLine>
            </>
          )}
          <FullLine>
            <LineNumber>9</LineNumber>
            <ExpandButton
              toolsExpanded={toolsExpanded}
              setToolsExpanded={setToolsExpanded}
            />
          </FullLine>

          <FullLine>
            <LineNumber>10</LineNumber>
            <NavigationItemCode
              url="https://forms.gle/4X42KeXhBMwFYU3o6"
              label="contact"
              text="<Contact />"
            />
          </FullLine>
          <FullLine>
            <LineNumber>11</LineNumber>
            <MyName>
              <TypeAnimation
                sequence={["</JoonKimchi>", 4000, () => {}]}
                wrapper="div"
                cursor={false}
                repeat={0}
                style={{ fontSize: textSizeForMediaScreenWidth }}
              />
            </MyName>
          </FullLine>
        </CodeWrapper>
      </Wrapper>
    </article>
  )
}
