import styled from "styled-components"
import { Link } from "react-router-dom"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 20vw;
  @media (max-width: 769px) {
    padding: 20px 8vw;
  }
`

export const HeadingRow = styled.div`
  display: flex;
  align-items: center;
`

export const Heading = styled.h1`
  color: #ffffff;
  font-size: 2.2em;
  margin-left: 1em;
  margin-bottom: 5px;
  margin-top: 5px;
  @media (max-width: 769px) {
    margin-left: 15px;
  }
`

export const LinkHeading = styled.h1`
  color: #ffffff;
  font-size: 1.7em;
  padding: 0px 5px;
  &:hover {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: yellow;
    color: black;
    background-color: rgb(0, 0, 0, 0.7);
    border: none;
  }
`

export const BodyText = styled.h2`
  color: #ffffff;
  font-weight: normal;
  font-size: 1em;
  margin-bottom: 2em;
`

export const CabbageLogo = styled.img`
  left: 36px;
  height: 2.2em;
  width: 2.2em;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
`

export const FullLine = styled.div`
  display: flex;
  align-items: center;
`

export const LineNumber = styled.h1`
  color: #595959;
  margin-right: 15px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: normal;
  font-size: 1.7em;
`
