import React, { useState, useEffect, useRef } from "react"
import { TypeAnimation } from "react-type-animation"

import { Wrapper, TextContainer, ExpandButtonStyled } from "./styles"

const TypingLink = ({ text }) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const isMobile = windowSize.current[0] < 769
  const textSizeForMediaScreenWidth = isMobile ? "18px" : "36px"
  return (
    <TypeAnimation
      sequence={[text, 500, () => {}]}
      wrapper="div"
      cursor={false}
      repeat={0}
      style={{ fontSize: textSizeForMediaScreenWidth }}
    />
  )
}

export default function ExpandButton({ top, toolsExpanded, setToolsExpanded }) {
  const [topText, setTopText] = useState("<ToolBox>...")
  const [toggled, setToggled] = useState(false)

  let textToDisplay = top ? topText : "</ToolBox>"

  useEffect(() => {
    if (toolsExpanded) {
      setTopText("<ToolBox>")
      setToggled(true)
    } else {
      setTopText("<ToolBox>...")
      if (!toggled) {
        setToggled(true)
      }
    }
    textToDisplay = topText
  }, [toolsExpanded, toggled])

  return (
    <Wrapper>
      <ExpandButtonStyled
        onClick={() => {
          setToolsExpanded(!toolsExpanded)
        }}
      >
        <TextContainer>
          {toggled ? (
            <div>{textToDisplay}</div>
          ) : (
            <TypingLink text={textToDisplay} />
          )}
        </TextContainer>
      </ExpandButtonStyled>
    </Wrapper>
  )
}
