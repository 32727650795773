import React from "react"
import { Element } from "react-scroll"

import TableOfContent from "../TableOfContent"
import OffsiteButton from "../OffsiteButton"

import {
  Wrapper,
  Heading,
  SubHeading,
  BodyText,
  CabbageLogo,
  HeadingRow,
  NumberLine,
  SubHeadingRow,
  EmphasizedBodyText,
  EmphasizedYellowBodyText,
  EmphasizedYellowBodyTextNoMarginLeft,
} from "../../styles"

import Cabbage from "../../../../assets/Cabbage.png"

export default function Resume() {
  const resourcesRef = { sectionName: "Resources" }
  const tldrRef = { sectionName: "TL;DR" }
  const contextRef = { sectionName: "Context" }
  const readabilityRef = { sectionName: "Readability" }
  const articulateRef = { sectionName: "Articulate" }
  const quantifyRef = { sectionName: "Quantify" }
  const individualityRef = { sectionName: "Individuality" }
  const resumeRefs = [
    resourcesRef,
    tldrRef,
    contextRef,
    readabilityRef,
    articulateRef,
    quantifyRef,
    individualityRef,
  ]

  return (
    <Wrapper>
      <TableOfContent sectionRefs={resumeRefs} />
      <HeadingRow>
        <CabbageLogo src={Cabbage} />
        <Heading>Optimize your resume</Heading>
      </HeadingRow>
      <Element name="Resources">
        <SubHeadingRow>
          <NumberLine>0</NumberLine>
          <SubHeading>Resources</SubHeading>
        </SubHeadingRow>
      </Element>
      <OffsiteButton
        label="→ Free template based off of my resume"
        url="https://docs.google.com/document/d/1bfIwLW1NxNHBDTx6_TVTAtwCtYgi_McwmuF8-OwopvU/edit?usp=sharing"
      />
      <OffsiteButton
        label="→ The resume that got me FAANG offers"
        url="https://docs.google.com/document/d/1VzxHhOLe6zCJnc_usqBO-fzNsB1OjEl9/edit?usp=sharing&ouid=108870502914037679947&rtpof=true&sd=true"
      />
      <Element name="TL;DR">
        <SubHeadingRow>
          <NumberLine>1</NumberLine>
          <SubHeading>TL;DR</SubHeading>
        </SubHeadingRow>
      </Element>
      <EmphasizedBodyText>
        1. Enhance readability: use straightforward formats and proofread
      </EmphasizedBodyText>
      <EmphasizedBodyText>
        2. Articulate your impact: explain the "what" before the "how" and avoid
        complex jargon
      </EmphasizedBodyText>
      <EmphasizedBodyText>
        3. Quantify your impact: support your points with metrics and numbers
        that speak for themselves
      </EmphasizedBodyText>
      <EmphasizedBodyText>
        4. Boast your individuality: work on projects you're passionate about
        and display your interests
      </EmphasizedBodyText>
      <Element name="Context">
        <SubHeadingRow>
          <NumberLine>2</NumberLine>
          <SubHeading>Context</SubHeading>
        </SubHeadingRow>
      </Element>
      <BodyText>
        {
          "The resume is a demo product you get to distribute to companies. This first product is so important because companies will either develop or lose interest in you based on your resume, and there is no way to recover lost interest. An easy exercise that contextualizes the importance of resumes is to put yourself in the companies' shoes. How likely is it that you will develop interest in someone if their demo product is of poor quality or low value?"
        }
      </BodyText>
      <BodyText>
        {
          "Your resume cannot and should not highlight everything you've ever done. Instead, it should carefully package your experiences to inspire interest, curiosity, and trust in the readers. The readers should walk away with a concrete answer to this question:"
        }
      </BodyText>
      <EmphasizedYellowBodyText>
        | Does this person have the potential to help our company succeed?
      </EmphasizedYellowBodyText>
      <BodyText>
        {
          "As the author, you have to help your readers answer this question. I’ve written dozens of resume drafts and received feedback from employers on them. Through this feedback, I’ve assembled the tools to optimize your resume."
        }
      </BodyText>
      <BodyText>
        {
          "Among the successful drafts, I've found it crucial to optimize your resume for 1) readability, 2) articulating your impact, 3) quantifying your impact, and 4) individuality."
        }
      </BodyText>
      <Element name="Readability">
        <SubHeadingRow>
          <NumberLine>3</NumberLine>
          <SubHeading>Readability</SubHeading>
        </SubHeadingRow>
      </Element>
      <BodyText>
        {
          "To improve the readability of your resume, you have to make it as clear as possible. Recruiters look at thousands of resumes in a given day. Make it easy for them to understand who you are and what you’ve done."
        }
      </BodyText>
      <BodyText>
        {
          "Start by visually structuring your resume so that it is as readable and digestible as possible. Avoid playing tetris with the sections of your resume. In the resources section, I’ve provided a free template that worked best for me."
        }
      </BodyText>
      <EmphasizedBodyText>
        {"Stick to straightforward formats and familiar fonts"}
      </EmphasizedBodyText>
      <BodyText>
        {
          "The format and the fonts are not where you demonstrate your expertise. Your resume should read top-to-bottom and left-to-right in just one scan."
        }
      </BodyText>
      <EmphasizedBodyText>
        {"Proofread for grammatical mistakes and misspelled words"}
      </EmphasizedBodyText>
      <BodyText>
        {
          "Grammar and spelling errors stick out like a sore thumb when I read my clients' resumes. For employers, they indicate that the applicant is careless and lazy. Read the resume out loud - this is the easiest way to spot awkward sentences and misspellings."
        }
      </BodyText>
      <Element name="Articulate">
        <SubHeadingRow>
          <NumberLine>4</NumberLine>
          <SubHeading>Articulate</SubHeading>
        </SubHeadingRow>
      </Element>
      <EmphasizedBodyText>
        {"Get to the point with active sentences"}
      </EmphasizedBodyText>
      <BodyText>
        {
          "Don't fluff up your sentences with adjectives and passive verbs. Arrive at the point immediately and state clearly what you did. Instead of ‘New customers were obtained by modern API complex endpoints that I wrote’, try ‘Drove 120% increase in customer sign-ups by implementing API endpoints’."
        }
      </BodyText>
      <EmphasizedBodyText>
        {"State the 'What' before the 'How'"}
      </EmphasizedBodyText>
      <BodyText>
        {
          "Prioritize the what before the how. In other words, emphasize what you’ve accomplished, rather than how you did it."
        }
      </BodyText>
      <BodyText>
        {
          "Instead of ‘Engineered APIs that enabled checkout for customers’, try ‘Enabled checkout for customers by engineering APIs."
        }
      </BodyText>
      <EmphasizedBodyText>
        {"Explain it like you would to your tech-savvy parent"}
      </EmphasizedBodyText>
      <BodyText>
        {
          "Unless you know that the recruiter reading your resume is technical, keep the sentences free of jargon. Many recruiters might not be coders themselves–they shouldn’t have to resort to Google to understand what you’re saying. It’s your job to explain the value you created."
        }
      </BodyText>
      <EmphasizedBodyText>{"<Bad example>"}</EmphasizedBodyText>
      <BodyText>
        {
          "Successfully implemented vStorage APIs for Storage Awareness Providers into the existing Java LightOS plugin to enable vVols for SAN/NAS array virtualization. \n "
        }
      </BodyText>
      <EmphasizedBodyText>{"<Better example>"}</EmphasizedBodyText>
      <BodyText>
        {
          "Improved company-wide efficiency of resources allocation for virtual machines by 50% after implementing vStorage APIs in existing Java LightOS."
        }
      </BodyText>
      <BodyText>
        {
          "The second example concretely states your works’ value - both its scale (company-wide) and amount (50%) - then explains how you arrived at such impact for credibility."
        }
      </BodyText>
      <Element name="Quantify">
        <SubHeadingRow>
          <NumberLine>5</NumberLine>
          <SubHeading>Quantify</SubHeading>
        </SubHeadingRow>
      </Element>
      <EmphasizedYellowBodyTextNoMarginLeft>
        The biggest takeaway: the impact of your previous work must come across
        to your readers.
      </EmphasizedYellowBodyTextNoMarginLeft>
      <EmphasizedBodyText>Numbers over words</EmphasizedBodyText>
      <BodyText>
        {
          "Stand out in the crowd of applicants by quantifying your impact. Recruiters and engineers understand numbers easier than words. Numbers and metrics speak for themselves."
        }
      </BodyText>
      <BodyText>
        {
          "If you implemented features, talk about how many people used the features. If you implemented code that saved a resource, express how much of that resource you saved."
        }
      </BodyText>
      <BodyText>
        {
          "Companies rely on their engineers to own their features: everything from scoping to results measurement. By supporting your points with metrics and numbers, you demonstrate ownership over your work beyond just implementation."
        }
      </BodyText>
      <EmphasizedBodyText>Playbook</EmphasizedBodyText>
      <BodyText>
        {"1. Identify the most impactful feature you shipped."}
      </BodyText>
      <BodyText>
        {
          "2. Pinpoint the metric you impacted. Resources, revenue, customers, engineers on your team, product engagement, etc."
        }
      </BodyText>
      <BodyText>
        {
          "3. Quantify the impact. Do your best to come up with an estimate if you don't have the exact numbers."
        }
      </BodyText>
      <Element name="Individuality">
        <SubHeadingRow>
          <NumberLine>6</NumberLine>
          <SubHeading>Individuality</SubHeading>
        </SubHeadingRow>
      </Element>
      <BodyText>
        Companies still value engineers that provide new perspectives.
        Engineering teams are highly collaborative, and they need independent
        engineers who can identify creative solutions.
      </BodyText>
      <EmphasizedBodyText>
        Pursue projects aligned with your passion
      </EmphasizedBodyText>
      <BodyText>
        Work on projects that you truly care about. This is the easiest way to
        display your individuality. In addition to having fun while building,
        you will have an easier time explaining what the project was about.
        People can sense when someone is passionate about their creation.
      </BodyText>
      <EmphasizedBodyText>List your interests at the bottom</EmphasizedBodyText>
      <BodyText>
        {
          "I highly encourage applicants to list their interests at the bottom of the resume inside the 'SKILLS & INTERESTS' section. Over several phone-screen interviews, I immediately connected with the recruiter due to our overlapping interests. It's an easy way to stand out just a little bit more :)"
        }
      </BodyText>
    </Wrapper>
  )
}
