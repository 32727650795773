import React from "react"
import PropTypes from "prop-types"

import { Wrapper, TextContainer, Weblink, ExternalLink } from "./styles"

export default function NavigationItem({ label, url, onsite }) {
  if (onsite) {
    return (
      <Weblink to={`/${label}`}>
        <Wrapper>
          <TextContainer>{label}</TextContainer>
        </Wrapper>
      </Weblink>
    )
  } else {
    return (
      <ExternalLink href={url} target="_blank" rel="noopener noreferrer">
        <Wrapper>
          <TextContainer>{label}</TextContainer>
        </Wrapper>
      </ExternalLink>
    )
  }
}

NavigationItem.propTypes = {
  loading: PropTypes.string,
}
