import styled from "styled-components"
import { Link } from "react-router-dom"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  @media (max-width: 769px) {
    justify-content: flex-start;
  }
`

export const TextContainer = styled.h3`
  color: #ffffff;
  text-align: left;
  font-size: 0.9375rem;
  &:hover {
    color: #595959;
  }
  @media (max-width: 769px) {
    font-size: 24px;
  }
`

export const Weblink = styled(Link)`
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
`

export const ExternalLink = styled.a`
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
`
