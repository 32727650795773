import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  border-bottom: 1px solid #595959;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 10px;
`
