import styled from "styled-components"
import { Link } from "react-router-dom"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 20vw;
  @media (max-width: 769px) {
    padding: 20px 8vw;
  }
`

export const HeadingRow = styled.div`
  display: flex;
  align-items: center;
`

export const Heading = styled.h1`
  color: #ffffff;
  font-size: 2.2em;
  margin-left: 1em;
  margin-bottom: 5px;
  margin-top: 5px;
  @media (max-width: 769px) {
    margin-left: 15px;
  }
`

export const LinkHeading = styled.h1`
  color: #ffffff;
  font-size: 1.7em;
  padding: 0px 5px;
  border: solid 1px white;
  &:hover {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: yellow;
    color: black;
    background-color: rgb(0, 0, 0, 0.7);
    border: none;
  }
`

export const SubHeading = styled.h1`
  color: #ffffff;
  font-size: 1.3em;
`

export const BodyText = styled.h2`
  color: #ffffff;
  font-weight: normal;
  font-size: 1em;
  margin-bottom: 1em;
`

export const EmphasizedBodyText = styled.h2`
  color: #ffffff;
  font-size: 1em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-left: 25px;
`

export const EmphasizedYellowBodyText = styled.h2`
  color: yellow;
  font-size: 1em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-left: 25px;
`

export const EmphasizedYellowBodyTextNoMarginLeft = styled.h2`
  color: yellow;
  font-size: 1em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
`

export const SubHeadingRow = styled.div`
  display: flex;
  align-items: center;
`

export const NumberLine = styled.h1`
  color: #595959;
  margin-right: 12px;
  font-size: 1.3em;
`

export const CabbageLogo = styled.img`
  left: 36px;
  height: 2.2em;
  width: 2.2em;
`

export const DownArrowButton = styled.img`
  height: 1.3em;
  filter: invert(80%);
  margin-right: 15px;
`

export const UpArrow = styled.img`
  height: 1.3em;
  filter: invert(80%);
  transform: scaleY(-1);
  margin-right: 15px;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
`
