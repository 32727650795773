import React from "react"

import {
  Wrapper,
  TextContainer,
  CabbageIcon,
  Weblink,
  WeblinkOffsite,
} from "./styles"

import Cabbage from "../../assets/Cabbage.png"

export default function NavigationItem({ label, onsite }) {
  if (onsite) {
    return (
      <Weblink to={`/${label}`}>
        <Wrapper>
          <CabbageIcon src={Cabbage} alt="cabbage" />
          <TextContainer>{label}</TextContainer>
          <CabbageIcon src={Cabbage} />
        </Wrapper>
      </Weblink>
    )
  }
  return (
    <WeblinkOffsite
      href="https://www.youtube.com/channel/UCZaAhM6oPPfJmoaESH7JuOg"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Wrapper>
        <CabbageIcon src={Cabbage} alt="cabbage" />
        <TextContainer>{label}</TextContainer>
        <CabbageIcon src={Cabbage} />
      </Wrapper>
    </WeblinkOffsite>
  )
}
