import React from "react"
import { Element } from "react-scroll"

import TableOfContent from "../TableOfContent"

import {
  Wrapper,
  Heading,
  SubHeading,
  BodyText,
  CabbageLogo,
  HeadingRow,
  NumberLine,
  SubHeadingRow,
  EmphasizedBodyText,
} from "../../styles"

import Cabbage from "../../../../assets/Cabbage.png"

export default function Applications() {
  const applicationsRefs = []
  return (
    <Wrapper>
      <TableOfContent sectionRefs={applicationsRefs} />
      <HeadingRow>
        <CabbageLogo src={Cabbage} />
        <Heading>Submitting applications</Heading>
      </HeadingRow>
      <Element name="TL;DR">
        <SubHeadingRow>
          <NumberLine>0</NumberLine>
          <SubHeading>TL;DR</SubHeading>
        </SubHeadingRow>
      </Element>
    </Wrapper>
  )
}
