import React from "react"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"
import styled from "styled-components"
import { Switch, Route, BrowserRouter as Router } from "react-router-dom"

import Home from "./containers/Home"
import About from "./containers/About"
import Tools from "./containers/Tools"
import Tool from "./containers/Tool"

import Cursor from "./components/Cursor"
import Header from "./components/Header"
import Footer from "./components/Footer"

import GlobalStyle from "./globalstyle"

const AppWrapper = styled.div`
  width: 100vw;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #181c12;
`

const TRACKING_ID = "UA-138424269-4"
ReactGA.initialize(TRACKING_ID)

function App() {
  return (
    <Router>
      <AppWrapper>
        <Helmet titleTemplate="%s - joonkimchi" defaultTitle="joonkimchi">
          <meta name="description" content="A personal site for joonkimchi" />
        </Helmet>
        <Cursor />
        <Header />
        <Switch>
          <Route path="/about" component={About} />
          <Route path="/toolbox/:tool" component={Tool} />
          <Route path="/toolbox" component={Tools} />'
          <Route path="/" component={Home} />'
        </Switch>
        <Footer />
        <GlobalStyle />
      </AppWrapper>
    </Router>
  )
}

export default App
