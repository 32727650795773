import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 2px;
  &:hover img {
    opacity: 1;
  }
`

export const TextContainer = styled.h1`
  color: #ffffff;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: normal;
  margin-left: 4vw;
  padding: 0px 5px;
  font-size: 36px;
  &:hover {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: yellow;
    color: black;
    background-color: rgb(0, 0, 0, 0.7);
  }
  @media (max-width: 769px) {
    font-size: 20px;
  }
`

export const ExpandButtonStyled = styled.button`
  background-color: #181c12;
  border: none;
  padding: 0px;
  &:hover {
    cursor: pointer;
  }
`
