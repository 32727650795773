import React, { useRef, useState } from "react"
import NavLink from "./components/NavLink"

import {
  Wrapper,
  SocialSection,
  Logo,
  LinksSection,
  StyledSocialIcon,
  BurgerWrapper,
  StyledMenu,
  MenuContainer,
  styles,
  LogoLink,
  GhostButtonToCloseMobileMenu,
  GhostPaddingForSocialSectionDesktop,
} from "./styles"

import Cabbage from "../../assets/Cabbage.png"

export default function Header() {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false)
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  if (windowSize.current[0] >= 769) {
    return (
      <Wrapper>
        <GhostPaddingForSocialSectionDesktop>
          <SocialSection>
            <StyledSocialIcon
              url="https://www.youtube.com/@JoonKimchi"
              fgColor="white"
              bgColor="transparent"
            />
            <StyledSocialIcon
              url="https://www.instagram.com/joon.kimchi/"
              fgColor="white"
              bgColor="transparent"
            />
            <StyledSocialIcon
              url="https://discord.gg/dhRq6VCFKQ"
              fgColor="white"
              bgColor="#181c12"
            />
            <StyledSocialIcon
              url="https://www.linkedin.com/in/joon-young-kim-442075137/"
              fgColor="white"
              bgColor="transparent"
            />
          </SocialSection>
        </GhostPaddingForSocialSectionDesktop>
        <LogoLink to="/">
          <Logo src={Cabbage} />
        </LogoLink>
        <LinksSection>
          <NavLink label="about" onsite />
          <NavLink label="toolbox" onsite />
          <NavLink label="contact" url="https://forms.gle/4X42KeXhBMwFYU3o6" />
        </LinksSection>
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <BurgerWrapper>
        <StyledMenu
          width="250px"
          styles={styles}
          isOpen={mobileMenuIsOpen}
          onStateChange={(state) => setMobileMenuIsOpen(state.isOpen)}
        >
          <MenuContainer>
            <GhostButtonToCloseMobileMenu
              onClick={() => setMobileMenuIsOpen(false)}
            >
              <LogoLink to="/">
                <Logo src={Cabbage} />
              </LogoLink>
            </GhostButtonToCloseMobileMenu>
            <GhostButtonToCloseMobileMenu
              onClick={() => setMobileMenuIsOpen(false)}
            >
              <NavLink label="about" onsite />
            </GhostButtonToCloseMobileMenu>
            <GhostButtonToCloseMobileMenu
              onClick={() => setMobileMenuIsOpen(false)}
            >
              <NavLink label="toolbox" onsite />
            </GhostButtonToCloseMobileMenu>
            <GhostButtonToCloseMobileMenu
              onClick={() => setMobileMenuIsOpen(false)}
            >
              <NavLink
                label="contact"
                url="https://forms.gle/4X42KeXhBMwFYU3o6"
              />
            </GhostButtonToCloseMobileMenu>
            <SocialSection>
              <StyledSocialIcon
                url="https://www.youtube.com/@JoonKimchi"
                fgColor="white"
                bgColor="transparent"
              />
              <StyledSocialIcon
                url="https://www.instagram.com/joon.kimchi/"
                fgColor="white"
                bgColor="transparent"
              />
              <StyledSocialIcon
                url="https://discord.gg/dhRq6VCFKQ"
                fgColor="white"
                bgColor="#181c12"
              />
              <StyledSocialIcon
                url="https://www.linkedin.com/in/joon-young-kim-442075137/"
                fgColor="white"
                bgColor="transparent"
              />
            </SocialSection>
          </MenuContainer>
        </StyledMenu>
      </BurgerWrapper>
      <LogoLink to="/">
        <Logo src={Cabbage} />
      </LogoLink>
      <div />
    </Wrapper>
  )
}
