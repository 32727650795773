import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 20vw;
  @media (max-width: 769px) {
    padding: 20px 8vw;
  }
`

export const HeadingRow = styled.div`
  display: flex;
  align-items: center;
`

export const Heading = styled.h1`
  color: #ffffff;
  font-size: 2.2em;
  margin-left: 1em;
  margin-bottom: 5px;
  margin-top: 5px;
  @media (max-width: 769px) {
    margin-left: 15px;
  }
`

export const SubHeading = styled.h1`
  color: #ffffff;
  font-size: 1.3em;
  margin-top: 0px;
`

export const BodyText = styled.h2`
  color: #ffffff;
  font-weight: normal;
  font-size: 1em;
  margin-bottom: 1em;
`

export const CabbageLogo = styled.img`
  left: 36px;
  height: 2.2em;
  width: 2.2em;
`

export const EmphasizedLink = styled.a``
