import styled from "styled-components"

export const Link = styled.a`
  font-size: 1.3em;
  font-weight: normal;
  color: black;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: yellow;
  margin: 5px 0px;
  padding: 1px 4px;
  &:hover {
    color: black;
    background-color: rgb(0, 0, 0, 0.7);
  }
`
