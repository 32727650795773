import styled from "styled-components"
import { Link } from "react-router-dom"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 60px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff;
  &:hover img {
    opacity: 1;
  }
`

export const TextContainer = styled.h1`
  color: #ffffff;
  text-align: left;
  font-size: 1.5rem;
  @media (max-width: 769px) {
    font-size: 1.3rem;
  }
`

export const CabbageIcon = styled.img`
  height: 36px;
  margin: 4px;
  opacity: 0;
`

export const Weblink = styled(Link)`
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
`

export const WeblinkOffsite = styled.a`
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
`
