import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 80px;
  margin-top: auto;
`

export const TimeText = styled.h3`
  margin-left: 10vw;
  color: white;
  font-size: 0.8rem;
  font-weight: normal;
`
