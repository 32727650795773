import styled from "styled-components"
import { SocialIcon } from "react-social-icons"
import { Link } from "react-router-dom"
import { slide as Menu } from "react-burger-menu"

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
`

export const GhostPaddingForSocialSectionDesktop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10vw;
`

export const SocialSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const LogoBox = styled.div`
  width: "100%";
  display: flex;
  justify-content: center;
`

export const Logo = styled.img`
  width: 50px;
  height: 50px;
`

export const LinksSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10vw;
`

export const NavigationLink = styled(Link)`
  font-size: 14px;
  color: white;
`

export const StyledSocialIcon = styled(SocialIcon)`
  width: 18px;
  height: 18px;
`

export const BurgerWrapper = styled.div`
  height: 40px;
  z-index: 200;
`

export const StyledMenu = styled(Menu)`
  z-index: 3000;
  background-color: #181c12;
`

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
`

export const LogoLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 769px) {
    align-self: center;
    margin: 0;
    padding: 0;
  }
`

export const GhostButtonToCloseMobileMenu = styled.button`
  margin: 0;
  padding: 0;
  background: #181c12;
  border: none;
  display: flex;
`

export const styles = {
  bmBurgerButton: {
    display: "flex",
    position: "relative",
    width: "24px",
    height: "20px",
    left: "36px",
    top: "10px",
  },
  bmBurgerBars: {
    background: "white",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "white",
  },
  bmMenu: {
    background: "#181c12",
    padding: "1.5em 1.5em 0",
    fontSize: "1.15em",
    maxHeight: "100vh",
  },
  bmMenuWrap: {
    position: "fixed",
    left: "0px",
    top: "0px",
    height: "100%",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    paddingTop: "0.8em",
    paddingLeft: "0.8em",
    paddingRight: "0.8em",
    height: "100%",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.9)",
  },
}
