import React from 'react';
import moment from 'moment';

import { Wrapper, TimeText } from './styles';

export default function Footer() {
  return (
    <Wrapper>
      <TimeText>{moment().format('MM/DD/YYYY hh:mm a')} NYC</TimeText>
    </Wrapper>
  );
}
