import React, { useRef } from "react"
import { Helmet } from "react-helmet"

import Applications from "./components/Applications"
import Resume from "./components/Resume"
import ResumeMobile from "./components/ResumeMobile"

export default function Tool({ match }) {
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const isMobile = windowSize.current[0] < 769

  const tool = match.params.tool
  const title = tool[0].toUpperCase() + tool.substring(1)
  return (
    <article>
      <Helmet>
        <title>{title}</title>
        <meta
          name={title}
          content="Best practices and tips that got me internships at FAANG companies"
        />
      </Helmet>
      {tool === "applications" && <Applications />}
      {tool === "resume" && (isMobile ? <ResumeMobile /> : <Resume />)}
    </article>
  )
}
