import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 8vw;
  top: 200px;
  @media (max-width: 769px) {
    display: none;
  }
`

export const Section = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  &:hover {
    border-bottom: 1px solid #595959;
    cursor: pointer;
  }
`
export const SectionText = styled.h1`
  color: #595959;
  font-size: 12px;
  margin-bottom: 3px;
`
