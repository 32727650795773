import React from "react"
import { Helmet } from "react-helmet"

import {
  Wrapper,
  Heading,
  BodyText,
  CabbageLogo,
  HeadingRow,
  LinkHeading,
  StyledLink,
  FullLine,
  LineNumber,
} from "./styles"

import Cabbage from "../../assets/Cabbage.png"

export default function Tools() {
  return (
    <article>
      <Helmet>
        <title>The tool Box</title>
        <meta
          name="Tools to use"
          content="Best practices and tips that got me internships at FAANG companies"
        />
      </Helmet>
      <Wrapper>
        <HeadingRow>
          <CabbageLogo src={Cabbage} />
          <Heading>Tool box</Heading>
        </HeadingRow>
        <BodyText>
          Below are tools created from insights and tips that I picked up
          through failures and successes while preparing for interviews in the
          tech industry. Each tool contains my perspective on a particular stage
          of the recruiting cycle and ways I gave myself the best chance to
          succeed.
        </BodyText>
        <FullLine>
          <LineNumber>0</LineNumber>
          <StyledLink to="toolbox/timeline">
            <LinkHeading>{"<Timeline />"}</LinkHeading>
          </StyledLink>
        </FullLine>
        <FullLine>
          <LineNumber>1</LineNumber>
          <StyledLink to="toolbox/applications">
            <LinkHeading>{"<Applications />"}</LinkHeading>
          </StyledLink>
        </FullLine>
        <FullLine>
          <LineNumber>2</LineNumber>
          <StyledLink to="toolbox/resume">
            <LinkHeading>{"<Resume />"}</LinkHeading>
          </StyledLink>
        </FullLine>
        <FullLine>
          <LineNumber>3</LineNumber>
          <StyledLink to="toolbox/interviewprep">
            <LinkHeading>{"<InterviewPrep />"}</LinkHeading>
          </StyledLink>
        </FullLine>
        <FullLine>
          <LineNumber>4</LineNumber>
          <StyledLink to="toolbox/behavioral">
            <LinkHeading>{"<Behavioral />"}</LinkHeading>
          </StyledLink>
        </FullLine>
        <FullLine>
          <LineNumber>5</LineNumber>
          <StyledLink to="toolbox/technical">
            <LinkHeading>{"<Technical />"}</LinkHeading>
          </StyledLink>
        </FullLine>
        <FullLine>
          <LineNumber>6</LineNumber>
          <StyledLink to="toolbox/Negotiating">
            <LinkHeading>{"<Negotiating />"}</LinkHeading>
          </StyledLink>
        </FullLine>
      </Wrapper>
    </article>
  )
}
