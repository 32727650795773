import React from "react"

import { Link } from "./styles"

export default function OffsiteButton({ url, label }) {
  return (
    <Link href={url} target="_blank" rel="noopener noreferrer">
      {label}
    </Link>
  )
}
