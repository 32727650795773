import React from "react"
import { Link } from "react-scroll"

import { Wrapper, Section, SectionText } from "./styles"

const SectionItem = ({ sectionRefDictionary, index }) => (
  <Section>
    <Link to={sectionRefDictionary.sectionName} smooth>
      <SectionText>
        {index}. {sectionRefDictionary.sectionName}
      </SectionText>
    </Link>
  </Section>
)

export default function TableOfContent({ sectionRefs }) {
  const createSection = (sectionRefDictionary, index) => (
    <SectionItem
      sectionRefDictionary={sectionRefDictionary}
      index={index}
      key={index}
    />
  )
  const sections = sectionRefs.map(createSection)

  return (
    <Wrapper>
      <SectionText>OUTLINE</SectionText>
      {sections}
    </Wrapper>
  )
}
