import React from "react"
import { Helmet } from "react-helmet"

import {
  Wrapper,
  Heading,
  SubHeading,
  HeadingRow,
  BodyText,
  CabbageLogo,
} from "./styles"

import OffsiteButton from "../Tool/components/OffsiteButton"

import Cabbage from "../../assets/Cabbage.png"

export default function About() {
  return (
    <article>
      <Helmet>
        <title>About</title>
        <meta name="About" content="About Joon Kim" />
      </Helmet>
      <Wrapper>
        <HeadingRow>
          <CabbageLogo src={Cabbage} />
          <Heading>Joon Kim</Heading>
        </HeadingRow>
        <SubHeading>
          "Life is short, so let's build cool stuff and eat some kimchi" -
          probably someone
        </SubHeading>
        <BodyText>
          Hey I'm Joon. I'm software engineer based in NYC. I like to solve
          problems and help others succeed.
        </BodyText>
        <HeadingRow>
          <CabbageLogo src={Cabbage} />
          <Heading>What I do</Heading>
        </HeadingRow>
        <BodyText>
          I'm currently @ Instagram. I have previous programming experiences at
          Facebook, Google, and fast growing startups. I love teaching and
          through content on various platforms, it is my goal to distribute
          materials of value for free.
        </BodyText>
        <HeadingRow>
          <CabbageLogo src={Cabbage} />
          <Heading>Work with me</Heading>
        </HeadingRow>
        <BodyText>
          I can help aspiring software engineers maximize their potential. With
          personal experience of being in the industry for several years and
          doing hundreds of recruiting interviews, I work with my clients
          individually to pinpoint gaps: optimize their resumes, interview
          skills, and project portfolios.
        </BodyText>
        <OffsiteButton
          label="→ Schedule a coaching session with me"
          url="https://forms.gle/4X42KeXhBMwFYU3o6"
        />
      </Wrapper>
    </article>
  )
}
