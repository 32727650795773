import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 10vw;
`

export const CodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const FullLine = styled.div`
  display: flex;
  align-items: center;
`

export const MyName = styled.h1`
  color: #ffffff;
  margin: 0px 2px;
  font-weight: normal;
`

export const Comment = styled.h1`
  color: #595959;
  margin: 0px 2px;
  font-weight: normal;
  font-size: 36px;
  @media (max-width: 769px) {
    font-size: 18px;
  }
`

export const LineNumber = styled.h1`
  color: #595959;
  margin-right: 5vw;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: normal;
  font-size: 36px;
  @media (max-width: 769px) {
    font-size: 20px;
  }
`

export const NavigationStack = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px;
  flex-direction: column;
`

export const ArrowButton = styled.button`
  position: absolute;
  left: 13vw;
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
`

export const Arrow = styled.img`
  margin-top: 3px;
  width: 1.5em;
  filter: invert(80%);
  @media (max-width: 769px) {
    width: 15px;
  }
`
